import AutoNumeric from 'autonumeric';
import { formatPhoneNumber } from '../../../backoffice/utils';
import '../../../../stylesheets/greeting-cards';
import { checkEmailConfirmation } from "../../utils";

var currency_type = window.currency_type || 'clp';
$(document).ready(function () {
  let currency = currency_type;
  let fintoc_bank = null;
  var disabled = false;

  $('.modal-img-card').click(function (event) {
    let url = this.getAttribute('data-url')
    $('#img-card-container-preview').attr(
      "src",
      `${url}`
    );
  });

  // Card Method selector
  $('.payment-button-card').click(({ currentTarget }) => {
    const kind = currentTarget.getAttribute('data-kind');
    fintoc_bank = currentTarget.getAttribute('data-bank');
    let fintoc_status = currentTarget.getAttribute('data-status');
    currency = currentTarget.hasAttribute('data-currency')
      ? currentTarget.getAttribute('data-currency')
      : currency;
    $('#currency-type-card').val(currency);
    if (!kind) return;
    $(`input:radio[value="${kind}"]`).prop('checked', true);
    $('input:hidden.transaction_kind').val(kind);
    if (kind == 'fintoc_payment' && !fintoc_bank && fintoc_status == 'inactive') {
      currentTarget.style.border = 'solid 2px';
      $('.fintoc-payment-button').removeClass('hidden-input');
      currentTarget.setAttribute('data-status', 'active');
      $(".fintoc-arrow").css("rotate", "90deg");
    } else if (kind == 'fintoc_payment' && !fintoc_bank && fintoc_status == 'active') {
      currentTarget.style.border = 'none';
      $('.fintoc-payment-button').addClass('hidden-input');
      currentTarget.setAttribute('data-status', 'inactive');
      $(".fintoc-arrow").css("rotate", "0deg");
    } else {
      $('#new_donator_card').submit();
    }
  });

  $('#dt_amount_card').on('input', (e) => {
    validateAmountToDonateCard(this);
  });

  const validateAmountToDonateCard = () => {
    currency = $('#currency-type-card')[0].value
    const amount_donator = $('#dt_amount_card');
    const current_amount = parseInt(amount_donator.val().replace(/\./g, ''));
    const min_clp = $('.greeting_card_amount').html();
    var min_amount = 3000;
    if (min_clp) {
      min_amount = {
        clp: parseInt(min_clp.replace(/\./g, '')),
        usd: $('#min-usd-amount').html(),
      }[currency];
    }

    if (current_amount >= min_amount) {
      $('#new_donator_card').removeClass('is-invalid');
      $('#new_donator_card').addClass('was-validated');
      $(`#amount_form_card div.invalid-feedback.payment-${currency}`).hide();
      return { valid: true, amount: current_amount };
    } else {
      $('#new_donator_card').addClass('is-invalid');
      $('#new_donator_card').removeClass('was-validated');
      $(`#amount_form_card div.invalid-feedback`).hide();
      $(`#amount_form_card div.invalid-feedback.payment-${currency}`).show();
      return { valid: false, amount: current_amount };
    }
  };

  //Show greeting_cards
  $('.show-type-button').click(function (event) {
    event.preventDefault();
    event.stopPropagation();
    const kind = $(this).attr('data-kind');
    const page = $(this).attr('data-page');
    $('.cards-container').hide();
    $('.charity-wreaths-container').hide();
    $(`.select-card-container[data-kind="${kind}"]`).show();
    if (page === '1') {
      $('.page-2').css('display', 'none');
      $('.page-1').css('display', 'flex');
    } else {
      $('.page-1').css('display', 'none');
      $('.page-2').css('display', 'flex');
    }
  });

  //Back greeting cards
  $('.back-button').click(function (event) {
    event.preventDefault();
    event.stopPropagation();
    $(`.${window.donation_type}-container`).show();
    $(`.select-card-container`).hide();
  });

  $('.card-template-input').blur(function (event) {
    var input_card_data = $(this).attr('data-kind');
    $(`#${input_card_data.replaceAll('_', '-')}-template`)[0].innerText = this.value;
    if (input_card_data == 'donator_name') {
      $('#intermediate-text-template').show();
    }
  });

  //Select greeting cards
  $('.select-card-design').click(function (event) {
    event.preventDefault();
    event.stopPropagation();
    $('#dt_greeting_card_id').val($(this).attr('data-card-id'));
    var amount = $(this).attr('data-card-amount');
    var physical_amount = $(this).attr('data-card-physical-amount');
    var usd_amount = $(this).attr('data-card-usd-amount');
    var usd_physical_amount = $(this).attr('data-card-usd-physical-amount');
    var kind = $(this).attr('data-card-kind');
    var image_url = $(this).attr('data-card-img');
    var allow_send_date = $(this).attr('data-card-allow-send-date');
    $('.greeting_card_amount').html(amount);
    $('.greeting_card_usd_amount').html(usd_amount);
    $('#min-amount').html(amount);
    $('#original-card-amount').html(amount);
    $('#min-usd-amount').html(usd_amount);
    $('#original-card-usd-amount').html(usd_amount);
    $('.greeting_card_digital_amount').html(amount);
    $('.greeting_card_physical_amount').html(physical_amount);
    $('.greeting_card_usd_digital_amount').html(usd_amount);
    $('.greeting_card_usd_physical_amount').html(usd_physical_amount);
    if (kind == 'charity_wreath' || kind === 'condolences' || kind === 'physical_condolences') {
      $('.condolence-field').css('display', '');
    } else {
      $('.condolence-field').css('display', 'none');
    }
    if (physical_amount && physical_amount != '') {
      $('.physical-field').css('display', '');
      $('.physical-field').prop('required', true);
    } else {
      $('.physical-field').css('display', 'none');
      $('.physical-field').prop('required', false);
    }
    if (usd_amount && usd_amount != '') {
      $('#currency-selection-card').show();
      $('#usd-min-amount-label').show();
    } else {
      $('#currency-selection-card').hide();
      $('#usd-min-amount-label').hide();
    }

    if (physical_amount && amount) {
      $('#physical-checkbox-container').show();
      $('.addReceiver').hide();
    } else if (physical_amount) {
      $('#subject_form_card').hide();
      $('#receiver_subject').prop('required', false);
      $('#address_form_card').show();
      $('#commune_form_card').show();
      $('#receiver_address').prop('required', true);
      $('#receiver_commune_id').prop('required', true);
      $('.addReceiver').hide();
    }
    if (kind == 'physical_condolences') {
      $('#subject_form_card').hide();
      $('#receiver_subject').prop('required', false);
      $('#message_form_card label').html(locale == 'en' ? 'Deceased' : 'Nombre Difunto');
      $('#message_form_card .invalidfeedback').html(
        locale == 'en' ? 'Enter the deceased name' : 'Ingresa el nombre del difunto'
      );
      $('#address_form_card').show();
      $('#commune_form_card').show();
      $('#receiver_address').prop('required', true);
      $('#receiver_commune_id').prop('required', true);
      $('.addReceiver').hide();
    }
    if (allow_send_date == 'true') {
      $('.send-date-field').css('display', '');
    } else {
      $('.send-date-field').css('display', 'none');
    }
    $('#card-img-container')[0].src = image_url;
    $('#card-payment-modal').modal('show');
    new AutoNumeric.multiple('.greeting_card_amount', {
      digitGroupSeparator: '.',
      decimalCharacter: ',',
      decimalPlaces: 0,
    });
  });

  $('#physical-checkbox').change(function (event) {
    if (this.checked) {
      $('#subject_form_card').hide();
      $('#greeting_card_sale_detail_subject').prop('required', false);
      $('#address_form_card').show();
      $('#commune_form_card').show();
      $('#receiver_address').prop('required', true);
      $('#receiver_commune_id').prop('required', true);
      $('.greeting_card_amount').html($('.greeting_card_physical_amount').html());
      $('#min-amount').html($('.greeting_card_physical_amount').html());
    } else {
      $('#subject_form_card').show();
      $('#greeting_card_sale_detail_subject').prop('required', true);
      $('#address_form_card').hide();
      $('#commune_form_card').hide();
      $('#receiver_address').prop('required', false);
      $('#receiver_commune_id').prop('required', false);
      $('.greeting_card_amount').html($('.greeting_card_digital_amount').html());
      $('#min-amount').html($('.greeting_card_digital_amount').html());
    }
    validateAmountToDonateCard();
    new AutoNumeric.multiple('.greeting_card_amount', {
      digitGroupSeparator: '.',
      decimalCharacter: ',',
      decimalPlaces: 0,
    });
  });

  $('.addReceiver').on('click', function (e) {
    let lastReceiver = $('.cards-receivers .row-receiver').length;
    e.preventDefault();
    const row = $('.cards-receivers .row-receiver').prop('outerHTML');
    $('.cards-receivers').append(
      row
        .replaceAll('data-index="0"', `data-index="${lastReceiver}"`)
        .replaceAll('[0]', `[${lastReceiver}]`)
    );
    $(`.cards-receivers .row-receiver[data-index="${lastReceiver}"] .btn-delete`).css(
      'display',
      'block'
    );
    $('.greeting_card_amount').html($('#original-card-amount').html() * (lastReceiver + 1));
    $('.greeting_card_usd_amount').html($('#original-card-usd-amount').html() * (lastReceiver + 1));
    validateAmountToDonateCard();
  });

  $('.cards-receivers').on('click', '.btn-delete', function (e) {
    e.preventDefault();
    const index = $(this).data('index');
    if (!index) return;
    $(`.row-receiver[data-index="${index}"]`).remove();
    let lastReceiver = $('.cards-receivers .row-receiver').length;
    $('.greeting_card_amount').html($('#original-card-amount').html() * lastReceiver);
    $('.greeting_card_usd_amount').html($('#original-card-usd-amount').html() * lastReceiver);
    validateAmountToDonateCard();
  });

  // Show card modal
  $('.show-card').click((event) => {
    event.preventDefault();
    event.stopPropagation();
    $('#image-modal .modal-body img').attr('src', event.currentTarget.getAttribute('value'));
    $('#image-modal').modal('show');
  });

  // Handle card form submit
  $('#new_donator_card').submit((event) => {
    event.preventDefault();
    event.stopPropagation();
    // dentro del listener del submit
    if (disabled) return;
    disabled = true;
    setTimeout(() => {
      disabled = false;
    }, 1500);
    const form = $('#new_donator_card');
    form[0].classList.add('was-validated');
    const url = form.attr('action');
    formatPhoneNumber();

    // parse amount value, to correct format
    const amountInput = document.getElementById('dt_amount_card');
    const amount_validate = validateAmountToDonateCard(amountInput);

    amountInput.value = amount_validate.amount || '';

    var validMail = $('#donator_email_card')[0]
      ? $('#donator_email_card')[0].checkValidity()
      : false;
    if (!validMail && $('#donator_email_card')[0] === undefined) validMail = true;
    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise
        .execute(recaptcha_site_key, { action: 'submit' })
        .then(function (token) {
          let captcha = $(`input[name="recaptcha_token"`);
          for (var i = 0; i < captcha.length; i++) {
            captcha[i].value = token;
          }
          if (
            form[0].checkValidity() === true &&
            amount_validate.valid === true &&
            validMail === true &&
            checkEmailConfirmation() === true
          ) {
            $('#loader').show();
            $('#card-payment-modal').modal('hide');
            $.ajax({
              type: 'POST',
              url: url,
              data: form.serialize(), // serializes the form's elements.
            })
              .done(function (response) {
                if (response['payment_stream'] == 'webpay') {
                  const url = response['url'];
                  const token = response['token'];
                  const form = $(
                    '<form action="' +
                      url +
                      '" method="post">' +
                      '<input type="hidden" name="' +
                      response['name'] +
                      '" value="' +
                      token +
                      '" />' +
                      '</form>'
                  );
                  $('body').append(form);
                  form.submit();
                } else if (response['payment_stream'] == 'khipu') {
                  window.location.href = response['url'];
                } else if (response['payment_stream'] == 'flow_servipag') {
                  window.location.href = response['url'];
                } else if (response['payment_stream'] == 'paypal') {
                  const form = $(response['form']);
                  $('body').append(form);
                  form.submit();
                } else if (response['payment_stream'] == 'mach') {
                  window.location.href = response['url'];
                } else if (response['payment_stream'] == 'manual_transfer') {
                  console.log('Nueva transacción manual.');
                } else if (response['payment_stream'] == 'fintoc_payment') {
                  const widget = Fintoc.create({
                    widgetToken: response['widget_token'],
                    publicKey: response['public_key'],
                    holderType: 'individual',
                    product: 'payments',
                    institutionId: fintoc_bank,
                    webhookUrl: response['webhook_url'],
                    onSuccess: () => {
                      window.location.href = response['success_url'];
                    },
                    onExit: () => {
                      alert('Transacción no realizada, por favor intenta nuevamente');
                      $('#loader').hide();
                    },
                  });
                  widget.open();
                } else if (response['refresh'] === true) {
                  window.scrollTo(0, 0);
                  window.location.reload();
                } else if (response['payment_stream'] == 'paypal_payment') {
                  $('#loader').hide();
                  $('#paypal-payment-modal').modal('show');
                  paypal
                    .Buttons({
                      env: response['env'],
                      createOrder: function () {
                        return response['token'];
                      },
                      onApprove: function (data) {
                        $('#paypal-payment-modal').modal('hide');
                        $('#loader').show();
                        return $.post('/paypal/capture_order', {
                          paymentToken: data.orderID,
                        }).then(function (executeData) {
                          window.location.replace(
                            response['completed_url'] + '/?dt_uuid=' + response['dt_uuid']
                          );
                        });
                      },
                      onCancel: function (data) {
                        $('#paypal-payment-modal').modal('hide');
                        $('#loader').show();
                        window.location.replace(
                          response['cancel_url'] + '/?dt_uuid=' + response['dt_uuid']
                        );
                      },
                    })
                    .render('#submit-paypal-payment');
                } else if (response['payment_stream'] == 'getnet_transaction') {
                  window.location.href = response['url'];
                } else if (response['payment_stream'] == 'invalid_email') {
                  alert('Mail inválido, por favor inténtelo de nuevo.');
                  $('#loader').hide();
                } else {
                  $('#loader').hide();
                  alert('Ha ocurrido un problema, te pedimos por favor reintentar la transacción');
                }
              })
              .fail(function (response) {
                $('#loader').hide();
                alert('Ha ocurrido un problema, te pedimos por favor reintentar la transacción');
                // return location.reload();
              });
            return false;
          } else {
            console.log('Validation not going through CARD');
            console.log('Form checkValidity: ' + (form[0].checkValidity() === true).toString());
            console.log('TaxID not required: ' + !$('#donator_tax_id').required);
            if (form[0].checkValidity() === false) {
              validateFormData(form[0]);
            }
          }
        });
    });
  });
});
